.body {
  box-shadow: inset -2px 2px 6px rgba(0, 0, 0, 0.16);
}

.individual {
  background: #535ad0;
}

.group {
  background: #8a8a8a;
}

.sidebar {
  width: 100px;
  left: 0px;
  top: 0;
  z-index: 99;
  box-shadow: inset -2px 2px 6px rgba(0, 0, 0, 0.16);
  position: sticky;
}

.logo {
  max-width: 100%;
  padding: 1em;
  position: fixed;
  width: 100px;
  z-index: 99;
  height: 69px;
}

.menuContainer {
  overflow-y: auto;
  overflow-x: hidden;
  top: 0px;
  bottom: 0px;
  position: fixed;
  scrollbar-width: thin;
}

.menuContainer::-webkit-scrollbar {
  width: 3px;
}
.menuContainer::-webkit-scrollbar-track {
  background: transparent;
}
.menuContainer::-webkit-scrollbar-thumb {
  background-color: #565257;
  border: 1px none #ffffff;
}

ul.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.system {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 10%;
}

ul.system li {
  margin: 0;
  padding: 0;
}

.itemMenu {
  width: 90px;
  padding: 2px 0px 0px;
}

.itemMenu:hover {
  background: #e0e0e080;
  /* border-radius: 15px 0 0 15px; */
  position: relative;
  right: -2px;
}

.itemMenu:hover a div {
  color: black;
  text-decoration: none;
}

.activeMenu {
  background: #f5f5f5;
  /* border-radius: 15px 0 0 15px; */
  position: relative;
  right: -2px;
}

.activeMenu div {
  color: black;
}

.activeMenu:hover > a:hover {
  text-decoration: none;
}

.activeMenu .sidebar-progress {
  color: #30358b;
  background: #e9eaf6;
  box-shadow: inset 1px 1px 2px 0px #00000045;
}

.sidebar-progress {
  color: #ffffff29;
  text-align: center;
  width: 100%;
  background: none;
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  padding-bottom: 1px;
  padding-top: 2px;
}
